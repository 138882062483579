import React from "react";
import { NavLink } from "react-router-dom";

const navItems = [
    {
        title: "Home",
        link: "/",
    },
    {
        title: "Services",
        link: "/service",
        subMenu: [
            {
                subtitle: "Services",
                sublink: "/service",
            },
            {
                subtitle: "IVF",
                sublink: "/service-details/1",
            },
            {
                subtitle: "Surrogacy and Donation",
                sublink: "/service-details/2",
            },
            {
                subtitle: "Ambulatory Gynecology",
                sublink: "/service-details/3",
            },
            {
                subtitle: "For Oncology Patients",
                sublink: "/service-details/4",
            },
        ],
    },
    // {
    //     title: "Blog",
    //     link: "/",
    // },
    {
        title: "For Donors",
        link: "/for-donors-patients/0",
    },
    {
        title: "For Patients",
        link: "/for-donors-patients/1",
    },
    {
        title: "About",
        link: "/about",
    },
    {
        title: "Contact",
        link: "/contact",
    },
    {
        title: "blog",
        link: "/blog",
    },
];

const MainMenu = () => {
    return (
        <nav>
            <ul className="main-menu">
                {navItems.map(({ title, link, subMenu }, index) => (
                    <li key={index}>
                        <NavLink className="main-menu-link" exact to={link}>
                            {title}
                        </NavLink>
                        {subMenu && (
                            <ul className="sub-menu">
                                {subMenu.map(({ subtitle, sublink }, index) => (
                                    <li key={index}>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL + sublink
                                            }
                                        >
                                            {subtitle}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default MainMenu;
