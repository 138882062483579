import SectionTitle from "../../../components/section-title";
import MedicalFeature from "../../../components/medical-feature";

const data = [
    {
        id: 1,
        icon: "icofont-prescription",
        title: "Medical Counseling",
        excerpt: "Get consultations from top level doctors",
    },
    {
        id: 2,
        icon: "icofont-doctor-alt",
        title: "Top Level Doctors",
        excerpt: "More than 50 experts around the world",
    },
    {
        id: 3,
        icon: "icofont-microscope",
        title: "Medical Facilites",
        excerpt: "World class facilities and modern technology",
    },
    {
        id: 4,
        icon: "icofont-ambulance-cross",
        title: "24 Hours Services",
        excerpt: "Immidiate assistance any part of the day",
    },
    {
        id: 5,
        icon: "icofont-blood",
        title: "Personal Services",
        excerpt: "Quality service with high precision",
    },
    {
        id: 6,
        icon: "icofont-paralysis-disability",
        title: "Dedicated Patient Care",
        excerpt: "Friendly and lovely environemet",
    },
];

const MedicalFeatureContainer = () => {
    return (
        <section className="feature-section bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            // subTitle="Welcome to SRGC "
                            title="Welcome to Double Y"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-xl-8">
                        <div
                            className="row icon-box-style"
                            data-aos="fade-up"
                            data-aos-duration="1100"
                        >
                            {data &&
                                data.map((single, key) => {
                                    return (
                                        <div key={key} className="col-md-6">
                                            <MedicalFeature
                                                key={key}
                                                data={single}
                                            />
                                        </div>
                                    );
                                })}
                            {/* <div className="col-md-6">
                                <p>
                                    Welcome to our center. Friendly, charming
                                    and professional staff, comfortable
                                    environment, will create a reliable mood,
                                    our green and well-maintained yard is the
                                    best waiting space for companions, we take
                                    care of your health and mood as well, and we
                                    try to make your dream come true here! We’re
                                    very proud of what we’ve achieved already.
                                    We’re the first clinic in the Caucasian
                                    region to offer women treatment with a new
                                    IVF technique cutting out much of the
                                    complexity and expense around egg
                                    fertilization and embryo development. We can
                                    guarantee honest and clear cooperation,
                                    we’re committed to helping you understand
                                    and assess your financial options Double Y
                                    founder and medical director professor Nino
                                    Museridze has spent twenty years delivering
                                    high quality fertility treatment. Our
                                    radical new approach to IVF treatment is the
                                    result of many years’ research by Nino and
                                    her team, as she trailed ways to bring down
                                    the cost of IVF.
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="thumb"
                data-aos="fade-left"
                data-aos-duration="1500"
            >
                <img
                    src={process.env.PUBLIC_URL + "./img/photos/doctor-01.png"}
                    alt="hope"
                />
            </div>
        </section>
    );
};

export default MedicalFeatureContainer;
