import React from "react";
import PricingItem from "../../components/pricing";
import SectionTitle from "../../components/section-title";
import HomeData from "../../data/home.json";

const Packages = () => {
    return (
        <div className="feature-section bg-gray ">
            <div className="container">
                <div className="row">
                    <div className="col-12 ">
                        <SectionTitle
                            subTitle="Service Packages"
                            title="<span>Guaranteed</span> Surrogacy Programs"
                            classOption="text-center"
                        />
                    </div>
                </div>
                <div
                    className={`row row-gutter-0 pricing-items-style1`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                >
                    {HomeData[7].pricing &&
                        HomeData[7].pricing.map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-sm-6 col-md-6 col-lg-4"
                                >
                                    <PricingItem key={key} data={single} />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default Packages;
