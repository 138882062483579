import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { Button, Layout, Menu } from "antd";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import PropTypes from "prop-types";
import AddBlog from "./addBlog";
import BlogList from "./blogList";
const AdminPage = ({ setUser, setRedirect }) => {
    const { Header, Content, Sider } = Layout;
    const items = [
        { key: 0, label: "Blogs", body: <BlogList /> },
        { key: 1, label: "Add a blog", body: <AddBlog /> },
    ];

    const [selectedTab, setSelectedTab] = useState(0);

    const userSignOut = () => {
        signOut(auth).then(() => {
            setUser(null);
            setRedirect(false);
        });
    };

    return (
        <Layout hasSider>
            <Sider
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                {" "}
                <div
                    style={{
                        height: "50px",
                        padding: "0 24px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="primary"
                        style={{ width: "100%" }}
                        onClick={userSignOut}
                    >
                        Sing Out
                    </Button>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={["0"]}
                    items={items}
                    onClick={(e) => setSelectedTab(e.key)}
                />
            </Sider>
            <Layout
                className="site-layout"
                style={{ marginLeft: 200, height: "100vh" }}
            >
                <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                    {items[selectedTab].body}
                </Content>
            </Layout>
        </Layout>
    );
};
AdminPage.propTypes = {
    setUser: PropTypes.func,
    setRedirect: PropTypes.func,
};

export default AdminPage;
