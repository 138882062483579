import { Button, List, Modal } from "antd";
import { collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import EditModal from "./editModal";
import { ExclamationCircleFilled } from "@ant-design/icons";
const BlogList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState();
    const [modalOpen, setModalOpen] = useState();
    const fetchData = async () => {
        const blogsColl = collection(db, "blogs");
        getDocs(blogsColl).then((res) => {
            setData(
                res.docs.map((doc) => {
                    return doc.data();
                })
            );
            setLoading(false);
        });
    };
    useEffect(() => {
        fetchData();
    }, []);

    const { confirm } = Modal;

    const showDeleteConfirm = (id) => {
        confirm({
            title: "Are you sure delete this blog?",
            icon: <ExclamationCircleFilled />,
            content:
                "The blog will be deleted and cannot be recovered afterwards",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                deleteDoc(doc(db, "blogs", `${id}`));
                fetchData();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    return (
        <>
            <EditModal
                data={edit}
                open={modalOpen}
                setModalOpen={setModalOpen}
            ></EditModal>
            <h5 style={{ color: "gray" }}>Blog List:</h5>
            <List
                itemLayout="horizontal"
                dataSource={data}
                loading={loading}
                renderItem={(item, index) => (
                    <List.Item
                        key={index}
                        actions={[
                            <Button
                                type="primary"
                                key="edit"
                                onClick={() => {
                                    setEdit(
                                        data.find((fItem) => {
                                            return fItem.id === item.id;
                                        })
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                Edit
                            </Button>,
                            <Button
                                type="primary"
                                danger
                                key="delete"
                                onClick={() => {
                                    showDeleteConfirm(item.id);
                                }}
                            >
                                Delete
                            </Button>,
                        ]}
                    >
                        <Link
                            style={{
                                color: "blue",
                                textDecoration: "underline",
                            }}
                            to={
                                process.env.PUBLIC_URL +
                                `/blog-details/${item.id}`
                            }
                        >
                            {item.title}
                        </Link>
                    </List.Item>
                )}
            ></List>
        </>
    );
};

export default BlogList;
