import { Link } from "react-router-dom";
import DepartmentWidget from "../../components/sidebar/department";
import PropTypes from "prop-types";
import SidebarWorkingTime from "../../components/sidebar/sidebar-working-time";
import Appointment from "../../components/sidebar/appointment";
import AccordionWrapTwo from "../../components/accordion/AccordionWrapTwo.jsx";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeData from "../../data/home.json";
import DonorsPatientsData from "../../data/for-donors-patients.json";
import CasesItem from "../../components/cases";
import ForDonorsPatientsDetails from "../../components/for-donors-patients";

SwiperCore.use([Pagination]);

const ForDonorsPatientsContainer = ({ data, id }) => {
    console.log(data);
    const swiperOption = {
        slidesPerView: 2,
        speed: 1000,
        loop: true,
        spaceBetween: 30,
        autoplay: false,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 2,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            576: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    console.log("id", id);
    return (
        <section className="department-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="department-wrpp">
                            <div className="sidebar-wrapper">
                                <DepartmentWidget
                                    suffix={true}
                                    data={DonorsPatientsData}
                                />
                                <SidebarWorkingTime />
                                <Appointment />
                            </div>

                            <div className="department-content">
                                <ForDonorsPatientsDetails data={data} />

                                <div className="faq-area">
                                    <h2 className="title">
                                        {id === "0" ? (
                                            <>
                                                <span>FAQ</span> about Surrogate
                                                Woman
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </h2>
                                    <div className="accordian-content">
                                        <AccordionWrapTwo id={id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ForDonorsPatientsContainer.propTypes = {
    data: PropTypes.object,
    id: PropTypes.string,
};

export default ForDonorsPatientsContainer;
