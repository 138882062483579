import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";

const navItems = [
    {
        title: "Home",
        link: "/",
    },
    {
        title: "Services",
        link: "/service",
        subMenu: [
            {
                subtitle: "IVF",
                sublink: "/service-details/1",
            },
            {
                subtitle: "Surrogacy and Donation",
                sublink: "/service-details/2",
            },
            {
                subtitle: "Ambulatory Gynecology",
                sublink: "/service-details/3",
            },
            {
                subtitle: "For Oncology Patients",
                sublink: "/service-details/4",
            },
        ],
    },
    // {
    //     title: "Blog",
    //     link: "/",
    // },
    {
        title: "For Donors",
        link: "/for-donors-patients/0",
    },
    {
        title: "For Patients",
        link: "/for-donors-patients/1",
    },
    {
        title: "About",
        link: "/about",
    },
    {
        title: "Contact",
        link: "/contact",
    },
];
const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div
            className={`offcanvas offcanvas-mobile-menu ${
                show ? "offcanvas-open" : ""
            }`}
        >
            <div className="inner">
                <div className="border-bottom mb-3 pb-3 text-end">
                    <button className="offcanvas-close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="offcanvas-head mb-3">
                    <div className="header-top-offcanvas">
                        <p>
                            <i className="icofont-google-map"></i>{" "}
                            <span>ADDRESS:</span> Shio Chitaidze St. Tbilisi,
                            Georgia
                        </p>
                    </div>
                </div>
                <nav className="offcanvas-menu">
                    <ul>
                        {navItems.map(({ title, link, subMenu }, index) => (
                            <li key={index}>
                                <NavLink
                                    exact
                                    to={process.env.PUBLIC_URL + link}
                                >
                                    <span className="menu-text">{title}</span>
                                </NavLink>
                                {subMenu && (
                                    <>
                                        <span
                                            className="menu-expand"
                                            onClick={onClickHandler}
                                            aria-hidden="true"
                                        ></span>
                                        <ul className="offcanvas-submenu">
                                            {subMenu.map(
                                                (
                                                    { sublink, subtitle },
                                                    index
                                                ) => (
                                                    <li key={index}>
                                                        <NavLink
                                                            to={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                sublink
                                                            }
                                                        >
                                                            {subtitle}
                                                        </NavLink>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="offcanvas-social my-4"></div>

                <ul className="media-wrap">
                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">WeChat</span>
                            <a
                                href=" http://wechat.com/dl/HfA3wAfmJFg%3D"
                                className="media-heading"
                            >
                                +995 591 11 86 88
                            </a>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">WeChat</span>
                            <a href=" http://wechat.com/dl/HfA3wAfmJFg%3D">
                                +86 15961735826
                            </a>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-envelope"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">email us</span>
                            <a
                                className="media-heading"
                                href="mailto:info@double-y.org"
                            >
                                info@double-y.org
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
