import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="this is title"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2979.2161873066602!2d44.7910002!3d41.6942685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40440ce95f5035b9%3A0x8c589c549beb14a3!2sHotel%20Astoria%20Tbilisi!5e0!3m2!1sen!2sge!4v1686059513747!5m2!1sen!2sge"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
