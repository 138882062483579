import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import AdminPage from "../components/admin/adminPage";
import { Button, Input } from "antd";

const Admin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    useEffect(() => {
        setLoading(true);
        const listen = onAuthStateChanged(auth, (us) => {
            console.log("auth", auth);
            if (us) {
                setUser(us);
                setRedirect(true);
                setLoading(false);
            } else {
                setUser(null);
                setLoading(false);
            }
        });
        return () => listen();
    }, [user]);

    const signIn = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, username, password)
            .then((userCredentials) => {
                // console.log("userCredentials", userCredentials);
                setRedirect(true);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    console.log("user", user);
    if (redirect) {
        return (
            <AdminPage setUser={setUser} setRedirect={setRedirect}></AdminPage>
        );
    }
    return (
        <div className="container-sm" style={{ marginTop: "35vh" }}>
            <div className="row d-flex justify-content-center">
                <div className="col-xl-3">
                    <form>
                        <div className="form-group mb-2">
                            <Input
                                type="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <Input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <Button
                            onClick={signIn}
                            type="primary"
                            style={{ width: "100%" }}
                            loading={loading}
                        >
                            Log in
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Admin;
