import React from "react";
import { Link } from "react-router-dom";
import PricingItem from "../pricing";
import HomeData from "../../data/home.json";
const PackagesPreview = () => {
    return (
        <div
            className={`row row-gutter-0 pricing-items-style1`}
            data-aos="fade-up"
            data-aos-duration="1100"
        >
            {HomeData[7].pricing &&
                HomeData[7].pricing.map((single, key) => {
                    return (
                        <div key={key} className="col-sm-6 col-md-6 col-lg-4">
                            <div className={single.classOption}>
                                <div className="pricing-title">
                                    <h5>{single.title}</h5>
                                </div>
                                {/* <div
                className="pricing-amount"
                dangerouslySetInnerHTML={{ __html: data.price }}
            ></div> */}
                                <div className="pricing-content">
                                    <p className="desc">{single.shortTitle}</p>
                                    {/* <ul className="pricing-list">
                                        {data.pricingList &&
                                            data.pricingList.map(
                                                (single, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <i className="icofont-check-circled"></i>
                                                            {single}
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul> */}
                                </div>
                                <div className="pricing-footer">
                                    <Link
                                        className={single.btnClassOption}
                                        to="/service"
                                    >
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default PackagesPreview;
