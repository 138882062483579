import SectionTitle from "../../../components/section-title";
import PricingItem from "../../../components/pricing";
import HomeData from "../../../data/home.json";
import PropTypes from "prop-types";
import PackagesPreview from "../../../components/packagesPreview/PackagesPreview";

const PricingContainer = ({ pricingClassOption }) => {
    return (
        <div className="pricing-area pricing-default-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Packages"
                            title="<span>Guaranteed 
                            </span> Surrogacy Programs"
                        />
                    </div>
                </div>
                <PackagesPreview></PackagesPreview>
            </div>
        </div>
    );
};

PricingContainer.propTypes = {
    pricingClassOption: PropTypes.string,
};
PricingContainer.defaultProps = {
    pricingClassOption: "row row-gutter-0 pricing-items-style1",
};

export default PricingContainer;
