import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitleTwo,
    AccordionContent,
} from "../accordion";
import PropTypes from "prop-types";

const AccordionWrapTwo = ({ id }) => {
    return id === "1" ? (
        <Accordion>
            <AccordionItem id="one">
                <AccordionTitleTwo id="one">
                    How does the surrogate woman conceive the baby?
                </AccordionTitleTwo>
                <AccordionContent id="one">
                    Once we successfully match Intended Parents with a surrogate
                    mother and all the legal procedures are met, we go ahead
                    with the IVF procedure. This involves carrying out hormonal
                    stimulation on the surrogate to allow for the cultivation of
                    embryos and their attachment to her uterine cavity.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitleTwo id="two">
                    Do you take care of the surrogate mother during pregnancy?{" "}
                </AccordionTitleTwo>
                <AccordionContent id="two">
                    Yes, we will perform multiple medical examinations
                    throughout the gestation period to ensure the success of the
                    process. There will be ultrasounds every month and
                    screenings for genetic pathologies on weeks 12 and 16.
                    <br></br>
                    Besides, we assign our surrogate mothers to psychologists to
                    help them throughout this emotionally draining process.
                    However, we understand that each surrogate mother has their
                    unique approach to a successful pregnancy – having been
                    through one already – hence we let them go through the
                    process as naturally as possible.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="tree">
                <AccordionTitleTwo id="tree">
                    Can a surrogate mother refuse to give the child?
                </AccordionTitleTwo>
                <AccordionContent id="tree">
                    No, all our surrogate moms sign a notarized contract with
                    the Intended Parents before the start of the IVF procedure,
                    indicating that they do not have any legal rights to the
                    newborn.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    ) : (
        <Accordion>
            <AccordionItem id="one">
                <AccordionTitleTwo id="one">
                    Will I be able to have children after egg donation?
                </AccordionTitleTwo>
                <AccordionContent id="one">
                    Yes. A normal female has a pool of about 400,000 follicles
                    (hence eggs) by the time of puberty. From these, only 400
                    will reach maturity and be ovulated. This leaves
                    approximately 399,600 unused. By ovarian stimulation, we
                    develop extra eggs that would otherwise be destroyed. This
                    explains why the normal pool of ovarian follicles is not
                    depleted by egg donation.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitleTwo id="two">
                    Will I miss school or work?
                </AccordionTitleTwo>
                <AccordionContent id="two">
                    Most appointments are scheduled for early in the morning so
                    the egg donor will have as little disruption to her schedule
                    as possible. The retrieval day will require an entire missed
                    day. Most egg donors return to school or work the following
                    day. Due to the time sensitive nature of the procedures, it
                    is most important that an egg donor recognize the level of
                    responsibility required in making and keeping appointments.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitleTwo id="three">
                    How long will this process take?
                </AccordionTitleTwo>
                <AccordionContent id="three">
                    Once an egg donor is in cycle, the process is quite short,
                    approximately 6 weeks. However, before beginning a cycle, an
                    egg donor must be “selected” and this can sometimes takes
                    several months.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="four">
                <AccordionTitleTwo id="four">
                    Does egg donation cost me anything?{" "}
                </AccordionTitleTwo>
                <AccordionContent id="four">
                    No. The prospective parents are responsible for all egg
                    donor costs incurred as a result of an egg donation cycle.
                    These expenses include egg donor compensation, all egg donor
                    medical costs, insurance, attorney fees and travel expenses.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="five">
                <AccordionTitleTwo id="five">
                    Can I donate eggs if my tubes are tied?
                </AccordionTitleTwo>
                <AccordionContent id="five">
                    Yes. The aspiration of eggs occurs before the eggs are
                    released by the body. Therefore it is irrelevant as to
                    whether an egg donor’s tubes are tied.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="six">
                <AccordionTitleTwo id="six">
                    Can I donate eggs if my tubes are tied?
                </AccordionTitleTwo>
                <AccordionContent id="six">
                    A physician will determine what medications will be given.
                    Over the course of approximately 3 weeks, an egg donor will
                    self-inject three different hormones. The first will prevent
                    ovulation. The second hormone medication is responsible for
                    the production of follicles (hence eggs). Lastly, a hormone
                    medication will be given to mature the eggs and induce
                    ovulation.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="seven">
                <AccordionTitleTwo id="seven">
                    What are the medications I must take?{" "}
                </AccordionTitleTwo>
                <AccordionContent id="seven">
                    A physician will determine what medications will be given.
                    Over the course of approximately 3 weeks, an egg donor will
                    self-inject three different hormones. The first will prevent
                    ovulation. The second hormone medication is responsible for
                    the production of follicles (hence eggs). Lastly, a hormone
                    medication will be given to mature the eggs and induce
                    ovulation.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="eight">
                <AccordionTitleTwo id="eight">
                    Are the medications I take safe?
                </AccordionTitleTwo>
                <AccordionContent id="eight">
                    The medications taken for fertility treatment and egg
                    donation are used throughout the world. This widespread use
                    is the result of rigorous testing for effectiveness and
                    safety by the scientific community.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="nine">
                <AccordionTitleTwo id="nine">
                    How do I give myself injections?
                </AccordionTitleTwo>
                <AccordionContent id="nine">
                    Instructions will be given on how to administer the daily
                    injections. All injections are subcutaneous (under the
                    skin), so they are easy to self-administer. The syringes
                    used have a very small needle and are usually well tolerated
                    by the egg donor.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="ten">
                <AccordionTitleTwo id="ten">
                    Will I undergo surgery?
                </AccordionTitleTwo>
                <AccordionContent id="ten">
                    No. Eggs are retrieved vaginally. There is no surgical cut.
                    For the egg donor’s comfort, the procedure is preformed
                    under IV sedation. It is required that the egg donor have a
                    companion to take her home as she is not allowed to drive
                    after the egg retrieval.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

AccordionWrapTwo.propTypes = {
    id: PropTypes.string,
};

export default AccordionWrapTwo;
