import { Button, DatePicker, Form, Input, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import moment from "moment";
import dayjs from "dayjs";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const EditModal = ({ open, data, setModalOpen }) => {
    const quillModules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["bold", "italic", "underline"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        {
                            color: [
                                "#8f1b1b",
                                "#000000",
                                "#e60000",
                                "#ff9900",
                                "#ffff00",
                                "#008a00",
                                "#0066cc",
                                "#9933ff",
                                "#ffffff",
                                "#facccc",
                                "#ffebcc",
                                "#ffffcc",
                                "#cce8cc",
                                "#cce0f5",
                                "#ebd6ff",
                                "#bbbbbb",
                                "#f06666",
                                "#ffc266",
                                "#ffff66",
                                "#66b966",
                                "#66a3e0",
                                "#c285ff",
                                "#888888",
                                "#a10000",
                                "#b26b00",
                                "#b2b200",
                                "#006100",
                                "#0047b2",
                                "#6b24b2",
                                "#444444",
                                "#5c0000",
                                "#663d00",
                                "#666600",
                                "#003700",
                                "#002966",
                                "#3d1466",
                                "custom-color",
                            ],
                        },
                        { background: [] },
                        "link",
                    ],
                ],
                handlers: {
                    color: function (value) {
                        if (value == "custom-color")
                            value = window.prompt("Enter Hex Color Code");
                        this.quill.format("color", value);
                    },
                },
            },
        };
    }, []);
    const [bodyText, setBodyText] = useState();
    useEffect(() => {
        if (data) setBodyText(data.body);
    }, [data]);

    console.log("data", typeof data?.id);
    const [form] = Form.useForm();
    console.log("form.getFieldValue", form.getFieldValue());
    const onOk = useCallback(() => {
        const formData = form.getFieldValue();
        console.log("bodytext", bodyText);
        updateDoc(doc(db, "blogs", String(data.id)), {
            date: moment(formData.date).format("DD/MM/YYYY"),
            body: bodyText,
            id: data.id,
            mediumImage: formData.mediumImage,
            title: formData.title,
        });
        setModalOpen(false);
    }, [data, bodyText, form, setModalOpen]);
    return (
        <Modal
            closable={false}
            open={open}
            width="80vw"
            onCancel={() => {
                setModalOpen(false);
            }}
            onOk={onOk}
            okText="Save"
        >
            <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
                style={{ maxWidth: 900 }}
                autoComplete="off"
            >
                <Form.Item
                    label="title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input Title of the blog!",
                        },
                    ]}
                    initialValue={data?.title}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Image Link"
                    name="mediumImage"
                    rules={[
                        {
                            required: true,
                            message: "Please input Link of the image!",
                        },
                    ]}
                    initialValue={data?.mediumImage}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Date"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: "Please input creation Date of the blog!",
                        },
                    ]}
                    initialValue={data ? dayjs(data.date, "DD/MM/YYYY") : null}
                >
                    <DatePicker></DatePicker>
                </Form.Item>
                <Form.Item label="Body">
                    <ReactQuill
                        onChange={(txt) => setBodyText(txt)}
                        modules={quillModules}
                        theme="snow"
                        value={bodyText}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
EditModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    setModalOpen: PropTypes.func,
};
export default EditModal;
