import { NavLink } from "react-router-dom";
import SidebarTitle from "../sidebar-title";
import PropTypes from "prop-types";
import { slugify } from "../../../utils";

const DepartmentWidget = ({ data, suffix }) => {
    return (
        <div className="widget-item">
            <SidebarTitle title="Departments" />
            <div className="widget-side-nav">
                <ul>
                    {data.map((single, i) => {
                        return (
                            <li key={i}>
                                <NavLink
                                    activeClassName="active"
                                    to={
                                        process.env.PUBLIC_URL +
                                        `${
                                            suffix
                                                ? `/for-donors-patients/`
                                                : `/service-details/`
                                        }${slugify(single.id)}`
                                    }
                                >
                                    {single.title}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

DepartmentWidget.propTypes = {
    data: PropTypes.array,
    suffix: PropTypes.bool,
};

export default DepartmentWidget;
