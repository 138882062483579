import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import Breadcrumb from "../containers/global/breadcrumb/index.jsx";
import DonorsPatientsData from "../data/for-donors-patients.json";
import PropTypes from "prop-types";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ForDonorsPatientsContainer from "../containers/for-donors-patients/index.jsx";

const ForDonorsPatients = ({
    match: {
        params: { id },
    },
}) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Double Y– Service Details" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <ForDonorsPatientsContainer
                            data={DonorsPatientsData[id]}
                            id={id}
                        />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ForDonorsPatients.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default ForDonorsPatients;
