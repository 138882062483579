import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    startAt,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import BlogListItem from "../../../components/blog-list";
import SidebarPost from "../../../components/sidebar/sidebar-post";
import SidebarTitle from "../../../components/sidebar/sidebar-title";
import { db } from "../../../firebase";
import { Pagination, Spin } from "antd";
import "./index.css";
const BlogListContainer = () => {
    const [data, setData] = useState();
    const PAGINATION_LIMIT = 4;
    const [page, setPage] = useState(0);
    const [length, setLength] = useState();
    const [recentPosts, setRecentPosts] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const first = query(collection(db, "blogs"));
            const documentSnapshots = await getDocs(first);
            setLength(documentSnapshots.docs.length);
            const after = page * PAGINATION_LIMIT;
            const lastVisible =
                documentSnapshots.docs[
                    documentSnapshots.docs.length - 1 - after
                ];
            const blogsColl = query(
                collection(db, "blogs"),
                orderBy("date", "desc"),
                startAt(lastVisible),
                limit(PAGINATION_LIMIT)
            );
            getDocs(blogsColl).then((res) => {
                setData(
                    res.docs.map((doc) => {
                        return doc.data();
                    })
                );
            });
            setLoading(false);
        };
        fetchData();
    }, [page]);

    useEffect(async () => {
        const first = query(collection(db, "blogs"));
        const documentSnapshots = await getDocs(first);
        const lastVisible =
            documentSnapshots.docs[documentSnapshots.docs.length - 1];
        const blogsColl = query(
            collection(db, "blogs"),
            orderBy("date", "desc"),
            startAt(lastVisible),
            limit(5)
        );
        getDocs(blogsColl).then((res) => {
            setRecentPosts(
                res.docs.map((doc) => {
                    return doc.data();
                })
            );
        });
    }, []);
    console.log("recentPosts", recentPosts);
    return (
        <div className="blog-area section-py blog-border-bottom">
            <div className="container">
                {loading ? (
                    <div className="row ">
                        <div className="col-12 d-flex justify-content-center">
                            <Spin spinning={loading} size="large"></Spin>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="post-items-style2">
                                {data &&
                                    !loading &&
                                    data.map((single, i) => {
                                        return (
                                            <BlogListItem
                                                key={i}
                                                data={single}
                                            />
                                        );
                                    })}
                            </div>
                            <div className="pagination-area mb-md-80">
                                {length && (
                                    <Pagination
                                        pageSize={PAGINATION_LIMIT}
                                        total={length}
                                        current={page + 1}
                                        onChange={(e) => {
                                            setPage(e - 1);
                                            window.scrollTo({
                                                top: 0,
                                                behavior: "smooth",
                                            });
                                        }}
                                    ></Pagination>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-wrapper blog-sidebar-wrapper">
                                {/* <div className="widget-item">
                                <SidebarTitle title="Search" />
                                <SidebarSearch />
                            </div> */}
                                {/* <div className="widget-item">
                                <SidebarTitle title="Categories" />
                                <SidebarCategories data={BlogData} />
                            </div> */}
                                <div className="widget-item">
                                    <SidebarTitle title="Recent posts" />
                                    {recentPosts && (
                                        <SidebarPost data={recentPosts} />
                                    )}
                                </div>
                                {/* <div className="widget-item">
                                <SidebarTitle title="TAGS" />
                                <SidebarTag data={BlogData} />
                            </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogListContainer;
