import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Georgia, <span>Tbilisi</span>
                </h2>
                <p>12 Shio Chitaidze St. Tbilisi, Georgia</p>
                <Link to="/">
                    <span>Email:</span> info@double-y.org
                </Link>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>WeChat</h6>
                    <p>+1 212-226-3126</p>
                </div>
                <div className="info-tem style-two mb-0">
                    <h6>WeChat 2</h6>
                    <p>+995 591 11 86 88</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
